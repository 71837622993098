/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');

// Datepicker
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.es');

// jQuery Validation
require('jquery-validation');
require('jquery-validation/dist/localization/messages_es.js');

// Bootstrap Toggle
require('bootstrap4-toggle');

// Bootstrap Select
require('bootstrap-select');

// Responsive Tabs
require('bootstrap-responsive-tabs');

//jQuery UI Sortable
require('jquery-ui/ui/widgets/sortable.js');

// Sweet Alert 2
require('sweetalert2');

//TinyMCE Editor
require('tinymce');
require('tinymce/themes/silver');
require('tinymce/plugins/paste');
require('tinymce/plugins/advlist');
require('tinymce/plugins/autolink');
require('tinymce/plugins/lists');
require('tinymce/plugins/link');
require('tinymce/plugins/image');
require('tinymce/plugins/imagetools');
require('tinymce/plugins/charmap');
require('tinymce/plugins/print');
require('tinymce/plugins/preview');
require('tinymce/plugins/anchor');
require('tinymce/plugins/textcolor');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/visualblocks');
require('tinymce/plugins/code');
require('tinymce/plugins/fullscreen');
require('tinymce/plugins/insertdatetime');
require('tinymce/plugins/media');
require('tinymce/plugins/table');
require('tinymce/plugins/contextmenu');
require('tinymce/plugins/code');
require('tinymce/plugins/help');
require('tinymce/plugins/wordcount');
require('../assets/tinymce/langs/es');
require('../assets/tinymce/langs/ca');

require('./general');
