
$('.clear-form').click(function (){
    $('form#filter-form input[type=text]').val('');
    $('form#filter-form select').val('');
    $('#filter-form').submit();
});

$('#pagination_option').change(function() {
    var pagination_number = $(this).val();
    $('#pagination_number').val(pagination_number);
    $('#filter-form').submit();
});

Array.prototype.clean = function(deleteValue) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == deleteValue) {
            this.splice(i, 1);
            i--;
        }
    }
    return this;
};

function base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== 'string') {
        return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
}

/* SWEET ALERT 2 - START */
window.swal = require('sweetalert2');

$(".delete-item-button").click(function (){

    var element = $(this);

    window.swal.fire({
        title: typeof element.attr('data-title') !== typeof undefined && element.attr('data-title') !== false ? element.attr('data-title') : '¿Are you sure?',
        text: typeof element.attr('data-text') !== typeof undefined && element.attr('data-text') !== false ? element.attr('data-text') : 'Please confirm or cancel.',
        type: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: typeof element.attr('data-confirm-text') !== typeof undefined && element.attr('data-confirm-text') !== false ? element.attr('data-confirm-text') : 'Ok',
        cancelButtonText: typeof element.attr('data-cancel-text') !== typeof undefined && element.attr('data-cancel-text') !== false ? element.attr('data-cancel-text') : 'Cancel'
    }).then((result) => {
        if(result.value){
            element.closest("form").submit();
        }
    });
});
/* SWEET ALERT 2 - END */

//RESPONSIVE TABS
$('.responsive-tabs').responsiveTabs({
    accordionOn: ['xs'] // xs, sm, md, lg
});

//BOOTSTRAP SELECT
$('.selectpicker').selectpicker({
    style: '',
    styleBase: 'form-control',
});

// DATEPICKER
$('.datepicker_input').datepicker({
    language: 'es',
    format: 'dd/mm/yyyy',
});
$('.datepicker').datepicker({
    language: 'es',
    format: 'dd-mm-yyyy',
    orientation: 'bottom auto',
});

/* IMAGE UPLOADER TYPE PROFILE - START */
jQuery('.input-file-button .file').unbind("click focus blur");

jQuery("#profile-image").click(function (){
    jQuery("input[name=profile_image]").trigger("click");
});

// For each .file element
jQuery('.input-file-button .file').each(function(){
    // Save some elements as variables
    var element = jQuery(this);
    var input = element.find('input');
    var value = element.find('.file-value');
    var image = element.closest('.image-editable-container').find('#profile-image');
    // Bind event handlers to <input>
    input.on({
        // On change, update the visible elements
        change: function () {

            var file = this.files[0];
            var reader = new FileReader();

            // Set preview image into the popover data-content
            reader.onload = function (e) {
                if(base64MimeType(e.target.result) !== false){
                    if(['image/png','image/jpeg', 'image/gif'].indexOf(base64MimeType(e.target.result)) !== -1){
                        image.attr('style', 'background-image: url("'+ e.target.result +'")');
                    }else{
                        image.attr('style', 'background-image: url("' + APP_URL + '/images/empty_profile.jpg")');
                    }
                }else {
                    image.attr('style', 'background-image: url("' + APP_URL + '/images/empty_profile.jpg")');
                }
            }

            if('undefined' !== typeof file){
                reader.readAsDataURL(file);

                // Get the value of the input
                var val = input.val();
                // Normalize strings
                val = val.replace(/\\/g, "/");
                // Remove the path
                val = val.substring(val.lastIndexOf("/") + 1);
                // Toggle the 'active' class based
                // on whether or not there is a value
                element.toggleClass('active', !!val.length);
                // Set the value text accordingly
                value.text(val);
                if(val != ''){
                    jQuery('.image-editable-label').html('<i class="fa fa-upload" aria-hidden="true"></i> ' + val);
                    // jQuery('.image-modified-overlay').css("opacity","0.5");
                }else{
                    jQuery('.image-editable-label').html('');
                    image.attr('style', 'background-image: url("' + APP_URL + '/images/empty_profile.jpg")');
                    // jQuery('.image-modified-overlay').css("opacity","0");
                }
            }else{
                jQuery('.image-editable-label').html('');
                image.attr('style', 'background-image: url("' + APP_URL + '/images/empty_profile.jpg")');
            }

        },
        // On focus, add the focus class
        focus: function () {
            element.addClass('focus');
        },
        // On blur, remove the focus class
        blur: function () {
            element.removeClass('focus');
        }
    });
});
/* IMAGE UPLOADER TYPE PROFILE - END */

// FILE INPUT START
function clearFileUpload(element){
    var parent = element.closest(".fileupload");
    parent.removeClass("fileupload-exists");
    parent.addClass("fileupload-new");

    parent.find(".fileupload-input").val("");
    parent.find(".fileupload-preview").text("");
}

function setFileUploaderEvents(){

    $(".fileupload .btn-danger").click(function () {
        clearFileUpload($(this));
    });

    $(".fileupload-input").change(function () {
        var file = $(this)[0].files[0];
        var parent = $(this).closest(".fileupload");
        var transparentPixel = $(this).attr('data-transparent-pixel');

        var image = parent.find('.file-to-upload .image-uploader-preview');
        image.attr('src', transparentPixel);
        image.parent().find('.file-to-upload-name').html("");

        if(file){
            var reader = new FileReader();

            // Set preview image into the popover data-content
            reader.onload = function (e) {
                if(base64MimeType(e.target.result) !== false){
                    if(['image/png','image/jpeg', 'image/gif'].indexOf(base64MimeType(e.target.result)) !== -1){
                        image.attr('src', e.target.result);
                    }else{
                        image.parent().find('.file-to-upload-name').html(file.name);
                    }
                }else {
                    image.attr('src', transparentPixel);
                }
            }
            reader.readAsDataURL(file);

            parent.find(".fileupload-preview").text(file.name);
            parent.removeClass("fileupload-new");
            parent.addClass("fileupload-exists");
        }else{
            clearFileUpload($(this));
        }
    });
}

setFileUploaderEvents();

$(".delete-existing-image").click(function () {

    var element = $(this);

    window.swal.fire({
        title: typeof element.attr('data-title') !== typeof undefined && element.attr('data-title') !== false ? element.attr('data-title') : '¿Are you sure?',
        text: typeof element.attr('data-text') !== typeof undefined && element.attr('data-text') !== false ? element.attr('data-text') : 'Please confirm or cancel.',
        type: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: typeof element.attr('data-confirm-text') !== typeof undefined && element.attr('data-confirm-text') !== false ? element.attr('data-confirm-text') : 'Ok',
        cancelButtonText: typeof element.attr('data-cancel-text') !== typeof undefined && element.attr('data-cancel-text') !== false ? element.attr('data-cancel-text') : 'Cancel'
    }).then((result) => {
        if(result.value){
            var imagesToDeleteString = $(".images-to-delete").val();
            var imagesToDeleteArray = imagesToDeleteString.split('-').filter(Boolean);
            imagesToDeleteArray.push($(this).attr("data-id"));

            $(".images-to-delete").val(imagesToDeleteArray.join("-"));

            $(this).closest(".image-block").fadeOut(200, function (){
                $(this).remove();

                $(".add-image-block").fadeIn(200);
            });
        }
    });
});

$(".delete-existing-file").click(function () {

    var element = $(this);

    window.swal.fire({
        title: typeof element.attr('data-title') !== typeof undefined && element.attr('data-title') !== false ? element.attr('data-title') : '¿Are you sure?',
        text: typeof element.attr('data-text') !== typeof undefined && element.attr('data-text') !== false ? element.attr('data-text') : 'Please confirm or cancel.',
        type: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: typeof element.attr('data-confirm-text') !== typeof undefined && element.attr('data-confirm-text') !== false ? element.attr('data-confirm-text') : 'Ok',
        cancelButtonText: typeof element.attr('data-cancel-text') !== typeof undefined && element.attr('data-cancel-text') !== false ? element.attr('data-cancel-text') : 'Cancel'
    }).then((result) => {
        if(result.value){
            var filesToDeleteString = $(".files-to-delete").val();
            var filesToDeleteArray = filesToDeleteString.split('-').filter(Boolean);
            filesToDeleteArray.push($(this).attr("data-id"));

            $(".files-to-delete").val(filesToDeleteArray.join("-"));

            $(this).closest(".file-item").fadeOut(200, function (){
                $(this).remove();

                $(".add-file-block").fadeIn(200);
            });
        }
    });
});
// FILE INPUT END

/* BOOTSTRAP CUSTOM FILE UPLOADER - START */
$(".custom-file-input").on("change", function() {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
});
/* BOOTSTRAP CUSTOM FILE UPLOADER - END */

/* TINYMCE - START */
var tinyMceOptions = {
    selector: ".editor-textarea",
    height: 700,
    language: APP_LOCALE,
    toolbar: 'undo redo | styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code link media image',
    relative_urls : false,
    remove_script_host : false,
    convert_urls : true,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
    ],
    content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css'
    ]
};

// IMPORTANT: If you want to use "image_list" and "image_upload" features, you need to include the following view in your component: "views/{backend or frontend}/includes/tinymceConfig"
if(typeof tinymce_image_list_url !== 'undefined'){
    tinyMceOptions.image_list = tinymce_image_list_url;
}
if(typeof tinymce_images_upload_url !== 'undefined'){
    tinyMceOptions.images_upload_url = tinymce_images_upload_url;
    tinyMceOptions.images_upload_handler = function (blobInfo, success, failure) {
        var xhr, formData;

        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', tinymce_images_upload_url);
        xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));

        xhr.onload = function() {
            var json;

            if (xhr.status != 200) {
                failure('HTTP Error: ' + xhr.status);
                return;
            }

            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != 'string') {
                failure('Invalid JSON: ' + xhr.responseText);
                return;
            }

            success(json.location);
        };

        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        xhr.send(formData);
    };
}

window.tinyMceEditor = tinymce.init(tinyMceOptions);
/* TINYMCE - END */

window.reloadPageWithCurrentTab = function(current_tab){
    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    search_params.set('current_tab', current_tab);
    url.search = search_params.toString();
    var new_url = url.toString();
    window.location.href = new_url;
}

/* RUTORIKA ORDER */
const changePosition = function(requestData){
    $.ajax({
        'url': '/sort',
        'type': 'POST',
        'data': requestData
    });
};

$(document).ready(function() {
    const $sortableTable = $('.sortable');
    if ($sortableTable.length > 0) {
        $sortableTable.sortable({
            handle: '.sortable-handle',
            axis: 'y',
            update: function(a, b){

                var entityName = $(this).data('entityname');
                var $sorted = b.item;

                var $previous = $sorted.prev();
                var $next = $sorted.next();

                if ($previous.length > 0) {
                    changePosition({
                        type: 'moveAfter',
                        entityName: entityName,
                        id: $sorted.data('itemid'),
                        positionEntityId: $previous.data('itemid')
                    });
                } else if ($next.length > 0) {
                    changePosition({
                        type: 'moveBefore',
                        entityName: entityName,
                        id: $sorted.data('itemid'),
                        positionEntityId: $next.data('itemid')
                    });
                }
            },
            cursor: "move"
        });
    }
});

